body {
  overflow: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Titillium Web", "Russo One",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, p {
  font-family: "Titillium Web";
}

h1 {
  padding-bottom: 10px;
  font-size: 1.4rem;
}

.component-title {
  font-family: "Russo One";
  color: #001E32;
  padding-top: 50px;
}

.navbar {
  padding: 10px;
  
}

#navbar a {
  text-decoration: none;
  color: white;
  font-family: "Russo One";
}

#navbar a:hover {
  color: #038540;
}

div.nav-item a.active {
  
}

.nav-pills .nav-link.active {
  background: #FEF6D0;
  color: #3b3b3b;
  border-radius: 0px;
  box-shadow: 0px -3px #bdbdbd inset;
}

.nav-pills a.nav-link.active {
  color: #3b3b3b !important;
}


#footer {
  color: #b2b2b2;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: 0;
}

button.navbar-toggler {
  padding: 20px;
}

input#formBasicEmail {
  padding: 30px;
}

input:focus#formBasicEmail {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input:focus#formBasicPassword {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input:focus#formBasicConfPassword {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input#formBasicPassword {
  padding: 30px;
}

input#formBasicPassword:active {
  outline: #3b3b3b;
}

input#formBasicConfPassword {
  padding: 30px;
}

.btn {
  padding: 20px;
  margin-top: 40px;
  border-radius: 0px;
  background: #b2b2b2;
  outline: none;
  border: none; 
}

.btn:hover {
  background: #3b3b3b;
}

label.form-label {
  font-weight: 600;
  font-size: 1.1rem;
}

.form-control {
  background: #e5e5e5;
  border-radius: 0px;
  border: none;
  border-bottom: 3px solid #bdbdbd;
}

.form-control:focus {
    border-color: #3b3b3b;
    background: #e5e5e5;
}

.form-control:focus {
  border-color: #3b3b3b;
  background: #e5e5e5;
}

#ComponentOne, #ComponentTwo, #ComponentThree h1 {
  padding: 40px;
}
