#ComponentOneDiv {
    background: #EFE9F4;  
}

#ComponentOne {
    display: contents;
    margin-top: 20px;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.3rem;
}

p {
    font-size: 1rem;
    line-height: 1.8rem;
}

.name-underline {
    background: rgba(0, 0, 0, 0.45);
    height: 2px;
    width: 75vh;
}

.accordion {
    padding-bottom: 50px;
}