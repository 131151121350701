#ComponentTwoDiv {
    background: #001E32;
}

#ComponentTwo {
    display: contents;
}

.card-header:hover {
    background: #001E32;
    color: white;
    cursor: pointer;
}

.white {
    color: white;
}

.border-on-hover {
    padding: 10px;
}

.border-on-hover:hover {
    border: 3px solid white;
    padding: 10px;
}

#project-cols a {
    text-decoration: none;
}

.project1, .project2, .project3, .project4 {
    border: 1px solid rgb(256, 256, 256, 0.4);
    color:rgb(256, 256, 256, 0.4);
}

.project1:hover {
    border: 1px solid transparent;
    transition: linear 0.3s;
    background: #030805;
    color: white;
    box-shadow: 0 3px 5px rgb(0, 0, 0, 0.7);
    
}

.project2:hover {
    border: 1px solid transparent;
    transition: linear 0.3s;
    background: #FB4F05;
    color: white;
    box-shadow: 0 3px 5px rgb(0, 0, 0, 0.7);
    
}

.project3:hover {
    border: 1px solid transparent;
    transition: linear 0.3s;
    background: #22151E;
    color: white;
    box-shadow: 0 3px 5px rgb(0, 0, 0, 0.7);
    
}

.project4:hover {
    border: 1px solid transparent;
    transition: linear 0.3s;
    background: #010E22;
    color: white;
    box-shadow: 0 3px 5px rgb(0, 0, 0, 0.7);   
}

.big-margin-bottom {
    padding-bottom: 5%;
}