#ComponentThreeDiv {
    background: white;
}

#ComponentThreeDiv .form-group {
    padding-bottom: 2%;
}

#ComponentThreeDiv .form-label {
    font-family: "Titillium Web";
}

#ComponentThreeDiv .form-control {
    font-family: "Titillium Web";
    background: white;
}

#ComponentThree {
    display: contents;
}

#ComponentThree a {
    text-decoration: inherit;
    color:rgb(0, 0, 0, 0.4);
}

#ComponentThree a:hover {
    color: #001E32;
}

.big-margin-bottom {
    padding-bottom: 5%;
}

.box {
    border: 2px solid blue;
    padding: 20px;
}

