#lazy-hero-text-overlay {

    color: whitesmoke;
    width: 100vw;
    padding-bottom: 60px;
    padding-top: 60px;
}

hr {
    background: rgba(256, 256, 256, 0.25);
    height: 2px;
    width: 10%;
}

.hero-margin {

}

.read-more {
    background: transparent;
    border: 1px solid rgb(256, 256, 256, 0.4);
    color:rgb(256, 256, 256, 0.4);
    box-shadow: none;
}

.read-more:hover {
    transition: linear 0.3s;
    border: 1px solid transparent;
    color: white;
    background: rgb(0, 30, 50, 0.9)
    
}

.title {
    color: white;
}

.white-color {
    color: white;
    font-size: 1.2rem;
}

.wider {
    width: 800px;
}

.bigger {
    font-size: 2rem;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: "Russo One";
}

.smaller {
    font-size: 1rem;
}